import logoone from "./../assests/visa-logo-png-visa-logo-png-transparent-amp-svg-vector-pluspng-2400x2400 (1) 1.png";
import logoone1 from "./../assests/image 10.png";
function CardTwo() {
  return (
    <div className="cardcotainertwo1">
      <div className="oneee">Payment Details</div>
      <div className="oneee11">
        Complete your purchased item by providing your new payment details
      </div>
      <div className="oneee22">
        <div className="oneee222">
          <img src={logoone} className="imagcardssss" />{" "}
        </div>
        <div className="oneee222">
          <img src={logoone1} className="imagcardssss" />{" "}
        </div>
      </div>
      <div className="oneee22we2">Add new payment method</div>
    </div>
  );
}

export default CardTwo;
