import React from "react";
import "./Style.css";
import image from "./../assests/image.png";
import Faq from "./Faq";
import logo from "./../assests/KenmoreLogoDark.png";
import Cardone from "./cardome";
import image1 from "./../assests/Rectangle 36.png";
import CardTwo from "./CardTwo";
import Cardthree from "./Cardthree";
function ThirdPage() {
  return (
    <div className="containerpagetwo">
      <div className="containerpagetwo__left">
        <Cardone />

        <img src={image1} alt="image" className="imageone11111111" />
      </div>
      <CardTwo />
      <Cardthree />
    </div>
  );
}

export default ThirdPage;
