import React, { useState } from "react";

import "./Style.css";
import imageone from "./../assests/image1.png";
import imagetwo from "./../assests/image2.png";
import imagethree from "./../assests/image3.png";
import logo from "./../assests/KenmoreLogoDark.png";
import { MdDone } from "react-icons/md";
import image from "./../assests/222 1.png";
function SecondPage() {
  return (
    <div className="containerpagetwo">
      <div className="seconddemotwo">
        <div className="key">Key Benefits:</div>
        <div className="benefet">
          <MdDone color="#F96300" size={40} /> Ensure your air purifier works at
          its best to keep your indoor air pristine.
        </div>
        <div className="benefet">
          <MdDone color="#F96300" size={40} /> Enjoy substantial savings on
          high-quality filters.
        </div>
        <div className="benefet">
          <MdDone color="#F96300" size={40} /> Don't miss out on this special
          deal. Grab your filters today before they're gone!
        </div>

        <div
          className="accesss11"
          onClick={() => {
            window.location.href =
              "https://wallettest.zblocks.io/login?returnUrl=%2Fverifier%3Fverify%3DZ1698929890106513%26id%3De8c9e0e6-3220-488b-8d34-5dee71b59dc1";
          }}
        >
          Unlock 30% discount
        </div>
      </div>
      <img src={image} alt="image" />

      <div className="qwertyui">
        Breathe easier and save more with{" "}
        <span style={{ color: "#F96300" }}>Home Depot's exclusive offer</span>.
        <br /> Get a fantastic
        <span style={{ color: "#F96300" }}>
          {" "}
          30% off on KENMORE air Purifier filters{" "}
        </span>
        for a limited time.
      </div>
    </div>
  );
}

export default SecondPage;
