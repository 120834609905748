// Importing Dependencies
import React from "react";
import "./Style.css";
import { AiOutlineCheckSquare } from "react-icons/ai";

function Cardthree() {
  return (
    <div className="cardcotainertwo">
      <div className="oneee">Card Information</div>
      <div className="oneee11">Card Number **</div>
      <div className="oneee222three"></div>
      <div className="oneee11">Expiration Date **</div>
      <div className="oneee222three1212">
        <div className="oneee222three12">Month</div>
        <div className="oneee222three12">Year</div>
      </div>
      <div className="oneee11">Security Code **</div>
      <div className="oneee222three12">CVV / CVC / CID</div>
      <div className="oneee22we2">
        {" "}
        <AiOutlineCheckSquare /> Remember this card for later use.
      </div>
    </div>
  );
}

export default Cardthree;
